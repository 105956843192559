import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import EventsOverview from "../components/EventsOverview";
import Pagination from "../components/Pagination";

export const EventPageTemplate = ({ events, pageContext }) => {

  const { numPages, currentPage } = pageContext
  const prevPage = currentPage - 1 === 1 ? "/events" : "/events/" + (currentPage - 1).toString()
  const nextPage = "/events/" + (currentPage + 1).toString()

  return (
    <div className="section-container events-overview-page">
      <section className="section">
        <EventsOverview events={events} showRedirect={false} />
        <Pagination numPages={numPages} currentPage={currentPage} prevPage={prevPage} nextPage={nextPage} />
      </section>
    </div>
  );
};

EventPageTemplate.propTypes = {
  events: PropTypes.array,
  pageContext: PropTypes.object,
};


const EventPage = ({ data, pageContext }) => {
  const { edges } = data.allMarkdownRemark
  return (
    <Layout>
      <EventPageTemplate events={edges} pageContext={pageContext} />
    </Layout>
  );
};

EventPage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default EventPage;

export const pageQuery = graphql`
  query EventPageTemplate($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {frontmatter: {templateKey: {eq: "event"}}}
      sort: {fields: frontmatter___publishDate, order: DESC}
      limit: $limit
      skip: $skip
      ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            startDate
            description
            heading
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            title
            subheading
            publishDate
          }
        }
      }
    }
  }
  `